import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Alert = makeShortcode("Alert");
const Link = makeShortcode("Link");
const CodeBlock = makeShortcode("CodeBlock");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`With open source `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`, you can use our provisioners to automate certificate enrollment for almost anything in your production network. To create a cloud VM certificate, we recommend you use the cloud provider metadata API and our IID provisioner. This short tutorial will show how to use Instance Identity Documents (IID) to authorize and retrieve an X.509 Certificate from `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`. `}</p>
    <p>{`IIDs are simply credentials that identify an instance's name and owner. By
presenting an IID in a request, a workload can prove that it is running on a cloud VM instance that you own.`}</p>
    <h2>{`About this tutorial`}</h2>
    <ul>
      <li parentName="ul">{`Learn how to automate enrollment of cloud VM certificates using the AWS, Azure, or GCP metadata API.`}</li>
      <li parentName="ul">{`Examples include copy/paste code blocks and specific commands for cloud providers.`}</li>
      <li parentName="ul">{`Estimated effort: Reading time ~5 mins, Lab time ~10 to 60 mins. `}</li>
    </ul>
    <Alert severity="info" mdxType="Alert">
  <div>
    If you run into any issues please let us know <Link href="https://github.com/smallstep/certificates/discussions" mdxType="Link">in GitHub Discussions</Link>.
  </div>
    </Alert>
    <h2>{`Requirements`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Open source -`}</strong>{` This tutorial assumes you have initialized and started up a `}<inlineCode parentName="li">{`step-ca`}</inlineCode>{` instance using the steps in `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-ca/getting-started"
        }}>{`Getting Started`}</a>{`. `}</li>
    </ul>
    <h2>{`IID-based authentication overview`}</h2>
    <p>{`The major clouds have different names for IIDs: AWS calls them `}<a parentName="p" {...{
        "href": "https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/instance-identity-documents.html"
      }}>{`instance
identity documents`}</a>{`, GCP calls them `}<a parentName="p" {...{
        "href": "https://cloud.google.com/compute/docs/instances/verifying-instance-identity"
      }}>{`instance identity tokens`}</a>{`, and Azure
calls them `}<a parentName="p" {...{
        "href": "https://docs.microsoft.com/en-us/azure/active-directory/managed-identities-azure-resources/how-to-use-vm-token"
      }}>{`access tokens`}</a>{`. The `}<em parentName="p">{`metadata`}</em>{` included in an IID also differs
between clouds, along with many other implementation details. In general,
they're all the same: signed bearer tokens that identify at least the
name and owner of a VM.`}</p>
    <p>{`Metadata API's expose IIDs via a non-routable IP address
(the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Link-local_address"
      }}>{`link-local address`}</a>{` 169.254.169.254). This magic is orchestrated by
the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Hypervisor"
      }}>{`hypervisor`}</a>{`, which identifies the requesting VM and services IID
requests locally.`}</p>
    <p>{`IIDs are very easy to get from within a VM via one unauthenticated HTTP
request. Barring any security issues, they're impossible to get from anywhere
else.`}</p>
    <p>{`As an example, let's fetch an IID on GCP. Since GCP encodes IIDs as JWTs and
makes their public keys available at a well-known endpoint, we can easily verify
and decode a GCP IID on the command line using `}<inlineCode parentName="p">{`step`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ curl -s -H "Metadata-Flavor: Google"
'http://metadata/computeMetadata/v1/instance/service-accounts/default/identity?audience=step-cli&amp;format=full'
| step crypto jwt verify --jwks https://www.googleapis.com/oauth2/v3/certs --aud step-cli --iss https://accounts.google.com { "header": {
"alg": "RS256", "kid": "afde80eb1edf9f3bf4486dd877c34ba46afbba1f", "typ":
"JWT" }, "payload": { "aud": "step-cli", "azp":
"117354011164720418655", "email":
"259112794408-compute@developer.gserviceaccount.com", "email_verified": true,
"exp": 1562780090, "google": { "compute_engine": {;
"instance_creation_timestamp": 1557864897, "instance_id":
"5404647754959331152", "instance_name": "foo", "project_id":
"step-instance-identity-test", "project_number": 259112794408, "zone":
"us-west1-b" { {, "iat": 1562776490, "iss":
"https://accounts.google.com", "sub": "117354011164720418655" },
"signature":
"AV8vZiNjOJNkWhWp5oy9R_WgGu3-tePyM4pKyHoela2SMVyWfpq4fPlSUxSPdzmfCT_akrUXrw-mDq7eLByqDOp3A4sGEZn9bY4Vmt5h9QYMVIo_60LRtC7c7QoBFZp2u3tNrPaI8ZhoINgHCsTdbfGEUDnCA8aH1mygd8b3kUEXcMCHrgUayPEVSMih8OYfmHUdecyTt0qOw6Ima16lX1jmM6lSoj8VNFmee36qFn58qULchB89lqviv-E0VzS5NthlqaM2_ukYNtKac-MdQdIlE86a-2YtgyXo4OVCpb87Svf2Rw9VaFsCKt4wFlRsnz4B3rx3I2bM2mXsQZY38Q"
}
`}</code></pre>
    <p>{`Your cloud provider signs IIDs. Therefore, additional API requests are not required.
In addition to being easy for clients, IID-based
authentication is scalable, performant, and highly available.`}</p>
    <p>{`By fetching an IID from the metadata API and presenting it in an HTTPS request
header a workload can prove that it's running on a VM under your control.
That’s how `}<inlineCode parentName="p">{`step`}</inlineCode>{` and `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` use IIDs.`}</p>
    <h2>{`Getting a certificate from step-ca using IID-based authentication`}</h2>
    <p>{`Here's a diagram of the basic architecture of the `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` IID-based authentication:`}</p>
    <p><img parentName="p" {...{
        "src": "https:/prof.infra.smallstep.com/graphics/iid-authentication.png",
        "alt": "Diagram of the basic architecture of step-ca’s IID-based authentication"
      }}></img></p>
    <p>{`To get a certificate from `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` using an IID we need to:`}</p>
    <ul>
      <li parentName="ul">{`Generate a key pair and a certificate signing request (CSR) with the workload's name`}</li>
      <li parentName="ul">{`Obtain an IID from the metadata API to authenticate to `}<inlineCode parentName="li">{`step-ca`}</inlineCode></li>
      <li parentName="ul">{`Submit the CSR and IID to `}<inlineCode parentName="li">{`step-ca`}</inlineCode>{` via HTTPS POST to obtain a certificate`}</li>
      <li parentName="ul">{`Store the certificate and private key somewhere our workload can find it`}</li>
    </ul>
    <p>{`While this is all standards-based and simple in theory, in practice, there’s a
lot of implementation detail to get right. Luckily, the `}<inlineCode parentName="p">{`step`}</inlineCode>{` utility
works seamlessly with `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` to do all of this for us.`}</p>
    <p>{`To demonstrate, assume we have `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` running on AWS with hostname
`}<em parentName="p">{`ca.local`}</em>{`.`}</p>
    <p>{`To enable IID-based authentication we’ll `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-ca/provisioners#cloud-provisioners"
      }}>{`configure`}</a>{` `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`,
adding an AWS-type provisioner.`}</p>
    <p>{`Find your `}<a parentName="p" {...{
        "href": "https://docs.aws.amazon.com/general/latest/gr/acct-identifiers.html"
      }}>{`AWS account ID`}</a>{` to restrict access to our VMs:`}</p>
    <p><img parentName="p" {...{
        "src": "https:/prof.infra.smallstep.com/graphics/aws-account-id.png",
        "alt": "AWS account ID"
      }}></img></p>
    <p>{`On the host running `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` add an AWS provisioner to your configuration by running:`}</p>
    <CodeBlock language="shell-session" copyText='step ca provisioner add "AWS IID Provisioner" --type AWS --aws-account 123456789042' mdxType="CodeBlock">
  {`$ step ca provisioner add "AWS IID Provisioner" --type AWS --aws-account 123456789042`}
    </CodeBlock>
    <p>{`Start or restart `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` to pick up this new configuration:`}</p>
    <CodeBlock language="shell-session" copyText="step-ca $(step path)/config/ca.json" mdxType="CodeBlock">
  {`$ step-ca $(step path)/config/ca.json`}
    </CodeBlock>
    <p>{`With the `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` server configured and running, let's use the `}<inlineCode parentName="p">{`step ca
bootstrap`}</inlineCode>{` command to configure a new VM instance to trust and connect to it:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step ca bootstrap --ca-url https://ca.local --fingerprint f501ed49263c1369bd490a85660ddd4388d4175e0337100a11d4e82eae496499
The root certificate has been saved in ~/.step/certs/root_ca.crt.
Your configuration has been saved in ~/.step/config/defaults.json.
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`--fingerprint`}</inlineCode>{` is for the root certificate used by `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`. Find it by
running the following command on your CA:`}</p>
    <CodeBlock language="shell-session" copyText="step certificate fingerprint $(step path)/certs/root_ca.crt" mdxType="CodeBlock">
  {`$ step certificate fingerprint $(step path)/certs/root_ca.crt`}
    </CodeBlock>
    <p>{`After bootstrapping, we're ready to get a certificate. If we pass our AWS IID
provisioner name to `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/certificate"
      }}><inlineCode parentName="a">{`step ca certificate`}</inlineCode></a>{` with the `}<inlineCode parentName="p">{`--provisioner`}</inlineCode>{` attribute,
`}<inlineCode parentName="p">{`step`}</inlineCode>{` will automatically use IID-based authentication to get a certificate:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step ca certificate foo.local foo.crt foo.key --provisioner "AWS IID Provisioner"
✔ Key ID: AWS IID Provisioner (AWS)
✔ CA: https://ca.local
✔ Certificate: foo.crt
✔ Private Key: foo.key
`}</code></pre>
    <p>{`The first positional argument to `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/certificate"
      }}><inlineCode parentName="a">{`step ca certificate`}</inlineCode></a>{` specifies our workload's
name, the certificate subject. In this case, it's `}<em parentName="p">{`foo.local`}</em>{`. The next two
positional arguments specify which files to write the certificate and private
key, respectively.`}</p>
    <p>{`We can use `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/certificate/inspect"
      }}><inlineCode parentName="a">{`step certificate inspect`}</inlineCode></a>{` to check our work:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step certificate inspect --short foo.crt
X.509v3 TLS Certificate (ECDSA P-256) [Serial: 4555...1939]
Subject:     foo.local
              ip-172-31-65-180.us-east-1.compute.internal
              172.31.65.180
Issuer:      My Intermediate CA
Provisioner: AWS IID Provisioner [ID: 8074...3263]
Valid from:  2019-07-08T21:39:40Z
       to:  2019-07-09T21:39:40Z
`}</code></pre>
    <p>{`Congratulations, that's it. Tell your workload to use `}<em parentName="p">{`foo.crt`}</em>{` and `}<em parentName="p">{`foo.key`}</em>{`,
configure clients to trust X.509 certificates signed by `}<inlineCode parentName="p">{`$(step
path)/certs/root_ca.crt`}</inlineCode>{`, and you're good to go. You now have a strong
standards-based mechanism to authenticate workloads and encrypt traffic using
TLS.`}</p>
    <h2>{`Next steps`}</h2>
    <p>{`Example configurations for GCP and Azure are available in the `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-ca/provisioners#cloud-provisioners"
      }}>{`step-ca
configuration docs`}</a>{`. Instead of account IDs, the GCP IID implementation restricts access
by project and/or service account. Azure restricts access by tenant. The `}<inlineCode parentName="p">{`step
CLI`}</inlineCode>{` abstracts away the remaining differences.`}</p>
    <p>{`Depending on your situation and tech stack, you might put these commands in a
startup script, put them in an AMI, or use configuration management for the
last piece of automation. If you’re using kubernetes, then IID-based
authentication isn’t right for you. Use `}<a parentName="p" {...{
        "href": "https://smallstep.com/docs/tutorials/kubernetes-acme-ca"
      }}>{`ACME`}</a>{`, `}<a parentName="p" {...{
        "href": "https://github.com/smallstep/autocert"
      }}>{`autocert`}</a>{` or `}<a parentName="p" {...{
        "href": "https://github.com/smallstep/step-issuer"
      }}>{`cert-manager
integration`}</a>{` instead.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      